import styled from 'styled-components';

import Layout from 'layouts/Layout';

export default {
  Layout: styled(Layout)`
    height: 100%;

    & .Layout-main {
      display: flex;
      align-items: center;
    }
  `,
};

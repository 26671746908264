import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ThemeProvider from 'components/theme/ThemeProvider';

import NotFound from 'views/NotFound';
import View from 'layouts/View';

const AppErrorFallback = memo(function AppErrorFallback({ error, theme }) {
  return (
    <ThemeProvider theme={theme}>
      <NotFound
        title={error?.status || 'Whoops...'}
        text={error?.message || 'Something went wrong'}
        Layout={View}
      />
    </ThemeProvider>
  );
});

AppErrorFallback.propTypes = {
  theme: PropTypes.object,
  error: PropTypes.object,
};

export default AppErrorFallback;

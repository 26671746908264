/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {};

/**
 * Slice
 */
export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    resetAppConfigState: (state) => {
      state = initialState;
      return state;
    },

    setAppConfigData: (state, action) => {
      state = {
        ...action.payload,
        phoneMask: action.payload.phoneMask.replace(/_/g, '0'),
      };

      return state;
    },
  },
});

/**
 * Thunks
 */

export const { resetAppConfigState, setAppConfigData } = appConfigSlice.actions;

export const fetchAppConfig = () => async (dispatch) => {
  const { data } = await axios.get('/public/AppConfig/');

  return dispatch(setAppConfigData(data));
};

/**
 * Selectors
 */
export const selectCaptchaKey = (state) => state.appConfig.captchaSiteKey;
export const selectPhoneMask = (state) => state.appConfig.phoneMask;
export const selectAchDeliveryData = (state) => {
  return {
    bankRoutingNumber: state.appConfig.achDeliveryBankRoutingNumber,
    bankAccountNumber: state.appConfig.achDeliveryBankAccountNumber,
    bankAccountName: state.appConfig.achDeliveryBankAccountName,
  };
};
export const selectWireTransferData = (state) => {
  return {
    bankRoutingNumber: state.appConfig.wireTransferBankRoutingNumber,
    bankAccountNumber: state.appConfig.wireTransferBankAccountNumber,
    bankAccountName: state.appConfig.wireTransferBankAccountName,
    swiftCode: state.appConfig.wireTransferSwiftCode,
    bankAddress: state.appConfig.wireTransferBankAddress,
  };
};

export default appConfigSlice.reducer;

import { useCallback } from 'react';

import { BUY, SELL } from 'consts';
import { useRouterHelper } from 'hooks/useRouterHelper';
import { useLS } from 'hooks/useLS';
import { useDidMount } from 'rooks';

const useMarketTypeLS = (props = {}) => {
  const { onChange: onChangeProp } = props;

  const { isBuy, isSell } = useRouterHelper();

  const [marketType, setMarketType] = useLS(
    'settings:marketType',
    isSell ? SELL : BUY,
  );

  /**
   * On mount set correct value
   */
  useDidMount(() => {
    if (isBuy && marketType === SELL) {
      setMarketType(BUY);
    }
    if (isSell && marketType === BUY) {
      setMarketType(SELL);
    }
  });

  const onChange = useCallback(
    (event, newValue, ...args) => {
      if (!newValue) return;

      setMarketType(newValue);
      onChangeProp?.(event, newValue, ...args);

      // if (isBuyOrSell) {
      //   setTimeout(() => {
      //     history.push(`/${newValue}/${isLeadBuyer ? LEADS : CLICKS}/overview`);
      //   }, 0);
      // }
    },
    [onChangeProp, setMarketType],
  );

  return {
    marketType,
    onMarketTypeChange: onChange,
  };
};

export { useMarketTypeLS };

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, DialogContent, Typography } from '@material-ui/core';

import Dialog from 'components/mui/Dialog';
import DialogTitle from 'components/mui/DialogTitle';
import Button from 'components/mui/Button';

import s from './styled';

const ConfirmDialog = memo(function ConfirmDialog({
  closeConfirm,
  options: {
    open,
    content,
    title,
    action = <Button onClick={() => closeConfirm()}>Ok</Button>,
    severity = 'success',
    disableBackdropClick = false,
  },
}) {
  return (
    <Dialog
      open={open}
      onClose={() => closeConfirm()}
      disableBackdropClick={disableBackdropClick}
      fullScreen={false}
      maxWidth={false}
      PaperComponent={s.Alert}
      PaperProps={{
        icon: false,
        action: null,
        TransitionProps: null,
        severity,
      }}
    >
      {title && (
        <DialogTitle disableTypography>
          <Typography component="h2" variant="h6">
            {title}
          </Typography>
        </DialogTitle>
      )}
      {content && <DialogContent>{content}</DialogContent>}
      {action && <DialogActions>{action}</DialogActions>}
    </Dialog>
  );
});

ConfirmDialog.propTypes = {
  closeConfirm: PropTypes.func,
  options: PropTypes.object,
};

export default ConfirmDialog;

import styled, { css } from 'styled-components';

import Alert from 'components/mui/Alert';

export default {
  Alert: styled(Alert)`
    margin: 18px;
    width: 100%;
    max-width: calc(100% - 36px);
    padding-top: 32px;
    padding-bottom: 32px;
    justify-self: stretch;

    & .MuiAlert-message {
      padding: 0;
    }

    & .MuiDialogTitle-root {
      padding-top: 0;
      padding-right: 24px;
    }

    & .MuiTypography-h6 {
      text-align: center;
    }

    & .MuiDialogContent-root {
      padding-bottom: 0.5rem;
      font-size: 1rem;
      text-align: center;
    }

    & .MuiDialogActions-root {
      flex-direction: column;
      padding-top: 1rem;
      padding-bottom: 0;

      &.MuiDialogActions-spacing > :not(:first-child) {
        margin: 10px 0 0;
      }
    }

    ${({ theme }) => css`
      ${theme.breakpoints.up('sm')} {
        margin: 0;
        align-self: flex-start;
        max-width: 100%;

        & .MuiDialogTitle-root {
          padding-left: 54px;
          padding-right: 54px;
          padding-bottom: 4px;
        }

        & .MuiDialogContent-root {
          border-radius: 0;
        }

        & .MuiDialogActions-root {
          flex-direction: row;
          justify-content: center;
          &.MuiDialogActions-spacing > :not(:first-child) {
            margin: 0 0 0 8px;
          }
        }
      }
    `}
  `,
};

const { REACT_APP_OT_DONT_SHARE_URL } = process.env;

export const footerLinks = [
  {
    label: 'Do Not Sell or Share My Personal Information',
    url: REACT_APP_OT_DONT_SHARE_URL,
    isExternalLink: true,
  },
  {
    label: 'Limit the Use of Sensitive Personal Information',
    url: REACT_APP_OT_DONT_SHARE_URL,
    isExternalLink: true,
  },
  {
    label: 'About Us',
    url: 'https://www.buyerlink.com/About/',
    isExternalLink: true,
  },
  {
    label: 'Contact Us ',
    url: 'https://buyerlink.com/Contact/',
    isExternalLink: true,
  },
  {
    label: 'Terms of Use',
    url: '/terms',
  },
  {
    label: 'Privacy Policy',
    url: '/privacy',
  },
  {
    label: 'Cookie Policy',
    url: '/cookie-policy',
  },
  {
    label: 'Vulnerability Policy',
    url: '/vulnerability-policy',
  },
  {
    label: 'Accessibility Statement',
    url: '/accessibility-statement',
  },
];

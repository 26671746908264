import React, { memo } from 'react';
import PropTypes from 'prop-types';

import s from './styled';

const RadioIcon = memo(function RadioIcon(props) {
  const { checked } = props;

  return (
    <s.Root>
      <s.RadioIcon className="RadioIcon-root" $checked={checked} {...props} />
    </s.Root>
  );
});

RadioIcon.propTypes = {
  checked: PropTypes.bool,
};

export default RadioIcon;

import React, {
  useCallback,
  useMemo,
  useState,
  createContext,
  useContext,
  memo,
} from 'react';
import axios from 'axios';

import createTheme from 'styles/theme';

const { REACT_APP_DOMAIN } = process.env;

/**
 * Custom theme context's
 */
const CustomThemeContext = createContext();

export const CustomThemeProvider = memo(function CustomThemeProvider(props) {
  // eslint-disable-next-line react/prop-types
  const { children } = props;

  const value = useCustomThemeInternal();

  return (
    <CustomThemeContext.Provider value={value}>
      {children}
    </CustomThemeContext.Provider>
  );
});

export const useCustomTheme = () => {
  return useContext(CustomThemeContext);
};

/**
 * Main logic
 * @returns {object}
 */

export const useCustomThemeInternal = () => {
  const [customTheme, setCustomTheme] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadTheme = useCallback((url) => {
    return (async () => {
      if (!url) {
        setCustomTheme(null);
        return;
      }

      setLoading(true);

      const { data } = await axios.get(`${REACT_APP_DOMAIN}${url}`);

      setLoading(false);
      setCustomTheme(data);

      return data;
    })();
  }, []);

  const theme = useMemo(() => {
    return createTheme(customTheme);
  }, [customTheme]);

  return {
    isCustom: !!customTheme,
    loading,
    loadTheme,
    theme,
  };
};

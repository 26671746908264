import { IconButton as IconButtonMui } from '@material-ui/core';
import styled from 'styled-components';

const IconButton = styled(IconButtonMui)`
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 1000;
`;

export default {
  IconButton,
};

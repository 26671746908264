import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Doppler from 'components/Doppler';

export const styles = {
  /* Styles applied to the root element. */
  root: {
    margin: 0,
    padding: '18px 24px 14px',
    flex: '0 0 auto',
  },

  typography: {
    textTransform: 'none',
  },

  caption: {
    marginTop: '0.25rem',
  },
};

const DialogTitle = React.forwardRef(function DialogTitle(props, ref) {
  const {
    children,
    caption,
    classes,
    className,
    disableTypography = false,
    TypographyProps,
    CaptionProps,
    ...other
  } = props;

  return (
    <Doppler className={clsx(classes.root, className)} ref={ref} {...other}>
      {disableTypography ? (
        <>
          {children}
          {caption}
        </>
      ) : (
        <>
          <Typography
            component="h2"
            variant="h6"
            {...TypographyProps}
            className={clsx(classes.typography, TypographyProps?.className)}
          >
            {children}
          </Typography>
          <Typography
            component="p"
            variant="caption"
            color="textSecondary"
            {...CaptionProps}
            className={clsx(classes.caption, CaptionProps?.className)}
          >
            {caption}
          </Typography>
        </>
      )}
    </Doppler>
  );
});

DialogTitle.propTypes = {
  children: PropTypes.node,
  caption: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  disableTypography: PropTypes.bool,
  TypographyProps: PropTypes.object,
  CaptionProps: PropTypes.object,
};

export default withStyles(styles, { name: 'MuiDialogTitle' })(DialogTitle);

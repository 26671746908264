import React, { memo } from 'react';
import Helmet from 'react-helmet';

import { useCustomTheme } from 'hooks/useCustomTheme';

const AppMeta = memo(function AppMeta() {
  const { theme } = useCustomTheme();

  const { custom: { googleFontUrl, favicons } = {} } = theme;

  return (
    <Helmet>
      <meta name="theme-color" content={theme.palette.background.dark.main} />

      {googleFontUrl && <link href={googleFontUrl} rel="stylesheet" />}

      {favicons?.x192 && (
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={favicons.x192}
        />
      )}
      {favicons?.x180 && (
        <link rel="apple-touch-icon" sizes="180x180" href={favicons.x180} />
      )}
      {favicons?.x32 && (
        <link rel="icon" type="image/png" href={favicons.x32} sizes="32x32" />
      )}
      {favicons?.x16 && (
        <link rel="icon" type="image/png" href={favicons.x16} sizes="16x16" />
      )}
      {favicons?.ico && <link rel="icon" href={favicons.ico} />}
      {/* <!-- OptanonConsentNoticeStart --> */}
      <script
        type="text/javascript"
        src={`https://cdn.cookielaw.org/consent/${process.env.REACT_APP_OT_COOKIE_CONSENT}/OtAutoBlock.js`}
      />
      <script
        type="text/javascript"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-domain-script={process.env.REACT_APP_OT_COOKIE_CONSENT}
      />
      <script type="text/javascript">{'function OptanonWrapper() {}'}</script>
      {/* <!-- OptanonConsentNoticeEnd --> */}
    </Helmet>
  );
});

AppMeta.propTypes = {};

export default AppMeta;

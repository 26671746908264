import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem as MuiListItem,
  ListItemAvatar,
  ListItemIcon as MuiListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';

import { THEME_BL } from 'consts';
import Doppler from 'components/Doppler';
import Dialog from 'components/mui/Dialog';
import Link from 'components/Link';

export const drawerOpenedWidth = 240;
export const drawerClosedWidth = 90;

const ListItemIcon = styled(MuiListItemIcon)`
  ${({ theme }) => {
    return css`
      color: ${theme.palette.background.dark.contrastText};
      transition: color 0.3s linear, font-size 0.3s linear;

      .MuiIcon-root {
        font-size: 1rem;
        transition: color, font-size 0.3s ease;
      }

      & .BlIcon-arrowIcon {
        font-size: 1.125rem;
        color: ${theme.palette.background.dark.contrastText};
      }
    `;
  }}
`;

const ListItem = styled(MuiListItem)`
  ${({ theme }) => css`
    padding-top: 0;
    padding-bottom: 0;

    & .MuiListItemText-primary {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.875rem;

      ${theme.id === THEME_BL &&
      css`
        font-family: ${theme.typography.fontFamilyAccent};
      `}
    }

    &.Mui-selected {
      cursor: default;

      & ${ListItemIcon} {
        color: ${theme.palette.primary.light};
      }
    }

    &.MuiListItem-button:hover {
      background-color: rgba(255, 255, 255, 0.04);
    }
  `}

  ${({ $nested }) =>
    $nested &&
    css`
      padding-left: 1.5rem;
    `}
`;

const ListBottom = styled.div`
  margin-top: auto;
  margin-bottom: 20px;

  & .MuiDivider-root {
    ${({ theme }) => {
      return css`
        margin-top: 1rem;
        margin-bottom: 1rem;

        ${theme.breakpoints.up('md')} {
          margin: 0.5rem;
        }
      `;
    }}
  }
`;

const ListUserInfo = styled(ListItem)`
  ${({ theme }) => {
    return css`
      display: flex;
      align-items: center;
      padding: 10px 16px 16px;

      ${theme.breakpoints.up('md')} {
        padding-left: 22px;
        padding-right: 22px;
      }

      & .MuiListItemText-primary {
        font-size: 1.125rem;
      }
    `;
  }}
`;

export default {
  Dialog: styled(Dialog)`
    z-index: 1150 !important;

    ${({ theme }) => {
      return css`
        & .MuiDialog-paper {
          padding-top: 80px;
          background-color: ${theme.palette.background.dark.main};
          color: ${theme.palette.background.dark.contrastText};
        }
      `;
    }}
  `,

  Drawer: styled(Drawer)(
    ({ $open, $isNotBuySell, $marketSell, theme }) => css`
      transition: width 0.15s linear;
      width: ${drawerOpenedWidth}px;

      & .MuiDrawer-paper {
        transition: width, border-right 0.15s linear;
        background-color: ${theme.palette.background.dark.main};
        color: ${theme.palette.background.dark.contrastText};
        border-right: 8px solid ${theme.palette.primary.light};
        width: ${drawerOpenedWidth}px;

        ${
          // theme.id === THEME_BL &&
          css`
            border-right-color: ${theme.palette.secondary.main};
          `
        }

        ${$marketSell &&
        css`
          border-right-color: ${theme.palette.info.main};
        `}

        ${$isNotBuySell &&
        css`
          border-right-color: ${theme.palette.success.main};
        `}
      }

      ${!$open &&
      css`
        width: ${drawerClosedWidth}px;

        & .MuiDrawer-paper {
          width: ${drawerClosedWidth}px;
          overflow: hidden;
        }

        & .MuiListSubheader-gutters {
          padding-left: 4px;
        }

        & .MuiListItem-gutters {
          padding-left: 24px;

          & .MuiIcon-root {
            font-size: 1.5rem;
          }
        }

        & ${ListBottom} {
          & ${ListItem}:not(${ListUserInfo}) {
            padding-left: 28px;
          }
        }

        & .MuiListItemAvatar-root {
          margin-right: 25px;
        }
      `}

      & ${ListItemIcon} {
        width: initial;
        margin-right: 15px;
      }
    `,
  ),

  LogoBox: styled(Doppler)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 30px 30px;
    color: #fff;
    font-size: 1.5rem;

    ${({ theme }) => css`
      color: ${theme.palette.background.dark.contrastText};
    `}

    ${({ $open }) =>
      !$open &&
      css`
        padding-left: 5px;
        padding-right: 5px;
      `}

    & img {
      max-width: none;
    }
  `,

  Divider: styled(Divider)`
    background-color: ${fade('#fff', 0.125)};
    height: 2px;

    ${({ theme }) => {
      return css`
        ${theme.breakpoints.up('md')} {
          margin-right: 0.5rem;
          margin-left: 0.5rem;
        }
      `;
    }}
  `,

  ListSubheader: styled(ListSubheader)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: inherit;
    line-height: 1;
    padding: 0 12px 30px;
    white-space: nowrap;
    position: unset;

    ${({ theme }) => {
      return css`
        font: 700 0.75rem ${theme.typography.fontFamilyAccent};
        & span {
          color: ${theme.palette.secondary.light};
        }

        ${theme.breakpoints.up('md')} {
          padding: 4px 4px 4px 16px;
        }
      `;
    }}
  `,

  MenuToggleButton: styled(IconButton)`
    margin-left: auto;
    transition: transform 0.15s ease;

    ${({ $open }) => {
      if (!!$open) return '';

      return css`
        margin-right: auto;
        transform: rotate(180deg);
      `;
    }}

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    & .MuiIcon-root {
      font-size: 1rem;

      ${({ theme }) => css`
        color: ${theme.palette.background.dark.contrastText};
      `}
    }
  `,

  List: styled(List)`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 10px 8px 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    ${({ theme }) => css`
      ${theme.breakpoints.up('md')} {
        padding: 0;
      }
    `}
  `,

  ListItem,

  ListItemIcon,

  ListItemText: styled(ListItemText)`
    ${() => css`
      & .MuiListItemText-primary {
        font-weight: bold;
        line-height: 1;
      }
    `}
  `,

  ListTop: styled(Doppler)`
    margin-left: -8px;
    margin-right: -8px;
    ${({ theme }) => css`
      ${theme.breakpoints.up('md')} {
        margin-left: 0;
        margin-right: 0;
      }
    `}
  `,

  ListTopItem: styled(ListItem)(
    ({ theme, $marketSell }) => css`
      min-height: 68px;
      border-left: 8px solid transparent;
      transition: border-color 0.3s linear;
      margin-bottom: 8px;
      padding-right: 0;

      ${$marketSell &&
      css`
        border-left-color: ${theme.palette.info.main};
      `}

      &.Mui-selected {
        background-color: rgba(255, 255, 255, 0.1);
        border-left-color: ${theme.palette.primary.light};

        ${
          // theme.id === THEME_BL &&
          css`
            border-left-color: ${theme.palette.secondary.main};

            & ${ListItemIcon} {
              color: ${theme.palette.secondary.light};
            }
          `
        }

        ${$marketSell &&
        css`
          border-left-color: ${theme.palette.info.main};

          & ${ListItemIcon} {
            color: ${theme.palette.info.light};
          }
        `}
      }

      ${theme.breakpoints.up('md')} {
        min-height: 50px;
        margin-bottom: 0;
        padding-right: 1rem;
        background-color: transparent;
        border-left-color: transparent;
      }

      & .MuiListItemText-primary {
        text-transform: none;
        font-size: 1.125rem;

        ${theme.id !== THEME_BL &&
        css`
          font-size: 15px;
          text-transform: uppercase;
          font-family: ${theme.typography.fontFamilyAccent};
        `}
      }
    `,
  ),

  ListBottom,

  ListBottomItem: styled(ListItem)`
    ${({ theme }) => css`
      min-height: 44px;
      border-left: 0;
      padding-left: 22px;

      &.Mui-selected {
        background-color: transparent;
        color: ${theme.palette.success.main};

        & ${ListItemIcon} {
          ${'' /* color: #fff; */}
          color: ${theme.palette.success.light};
        }
      }

      ${theme.breakpoints.up('md')} {
        padding-left: 32px;
      }
    `}
  `,

  ListUserInfo,

  ListItemAvatar: styled(ListItemAvatar)`
    min-width: 0;
    margin-right: 10px;

    & .MuiAvatar-circle {
      width: 35px;
      height: 35px;
      padding-right: 4px;

      ${({ theme }) => {
        return css`
          font: italic 700 1.25rem ${theme.typography.fontFamilyAccent};
          background-color: ${theme.palette.primary.light};
          color: ${theme.palette.background.dark.main};
        `;
      }}
    }

    & .MuiAvatar-fallback {
      margin-right: -4px;
    }
  `,

  FooterListItem: styled.span`
    margin: 8px 20px;
    display: inline-block;
  `,

  Link: styled(Link)`
    padding: 2px;
    font-size: 0.75rem;

    ${({ theme }) => {
      return css`
        color: ${theme.palette.background.dark.contrastText};
      `;
    }}
  `,
  ExternalLink: styled.a`
    padding: 2px;
    font-size: 0.75rem;

    ${({ theme }) => {
      return css`
        color: ${theme.palette.background.dark.contrastText};
      `;
    }}
  `,
};

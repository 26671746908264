import styled, { css } from 'styled-components';
import { AppBar, IconButton, Toolbar } from '@material-ui/core';

import Doppler from 'components/Doppler';

const height = '72px';

export default {
  MobileHeaderWrapper: styled(AppBar)`
    z-index: 1200;

    ${({ theme }) => css`
      background-color: ${theme.palette.background.dark.main};
    `}
  `,

  Toolbar: styled(Toolbar)`
    height: ${height};
    padding: 20px;
  `,

  LogoBox: styled(Doppler)`
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    & img {
      max-width: none;
      max-height: 100%;
    }
  `,

  MenuToggleButton: styled(IconButton)`
    margin-left: auto;
    transition: transform 0.15s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .MuiIcon-root {
      font-size: 1.25rem;

      ${({ theme }) => css`
        color: ${theme.palette.background.dark.contrastText};
      `}
    }
  `,

  Spacer: styled.div`
    height: ${height};
    display: block;
    width: 100%;
  `,
};

import styled, { css } from 'styled-components';

export default {
  CheckIcon: styled.span`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    line-height: 1;
    border: 1px solid ${({ theme }) => theme.palette.action.disabled};
    transition: border-color 0.3s;

    &::before {
      opacity: 0;
      font-size: 0.75rem;
      content: '\\e927';
      font-family: icomoon;
    }

    ${({ $checked, theme }) => {
      if (!$checked) return '';

      return css`
        border-color: transparent;
        background-color: currentColor;

        &::before {
          opacity: 1;
          color: ${theme.palette.background.dark.contrastText};
        }
      `;
    }}
  `,
};

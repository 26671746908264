import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useBreakpoints } from 'helpers/styles';
import { useLocalstorageState } from 'rooks';
import Header from './Header';
import MobileHeader from './MobileHeader';
import MainDrawer from './MainDrawer';
import Footer from './Footer';

import s from './styled';

const Layout = memo(function Layout(props) {
  const { children, className, showNav = true, ...rest } = props;

  const { isMdUp } = useBreakpoints();

  // const [mainDrawerOpen, setMainDrawerOpen] = useState(isMdUp);
  const [mainDrawerOpen, setMainDrawerOpen] = useLocalstorageState(
    'settings:mainDrawerOpen',
    true,
  );

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const onMainDrawerToggle = useCallback(
    (_, open) => {
      if (isMdUp) {
        setMainDrawerOpen(open);
      } else {
        setMobileMenuOpen(open);
      }
    },
    [setMainDrawerOpen, isMdUp],
  );

  return (
    <s.View
      className={clsx(className, {
        'Layout-drawerOpened': mainDrawerOpen,
        'Layout-drawerClosed': !mainDrawerOpen,
      })}
      {...rest}
    >
      {!isMdUp && (
        <MobileHeader open={mobileMenuOpen} onToggle={onMainDrawerToggle} />
      )}
      <s.DrawerBox>
        <MainDrawer
          open={isMdUp ? mainDrawerOpen : mobileMenuOpen}
          onToggle={onMainDrawerToggle}
        />
        <s.MainBox $mainDrawerOpen={mainDrawerOpen}>
          {showNav && <Header open={mainDrawerOpen} />}
          <s.Main className="Layout-main">{children}</s.Main>
        </s.MainBox>
      </s.DrawerBox>
      <Footer mainDrawerOpen={mainDrawerOpen} />
    </s.View>
  );
});

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  showNav: PropTypes.bool,
};

export default Layout;

import React from 'react';
import { Skeleton } from '@material-ui/lab';
import {
  camelCase,
  cloneDeep,
  isArray,
  isBoolean,
  isEmpty,
  isNil,
  isNull,
  isNumber,
  isPlainObject,
  isString,
  map,
  mapKeys,
  mapValues,
  snakeCase,
  truncate,
} from 'lodash';
import { Tooltip } from '@material-ui/core';

export function sleep(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function sortArrayByKey(array, key = 'name') {
  return array.sort((a, b) => {
    if (!a[key]) {
      throw new Error(`The object ${a} has no "${key}" field.`);
    }

    const keyA = a[key].toLowerCase();
    const keyB = b[key].toLowerCase();

    // sort string ascending
    if (keyA < keyB) {
      return -1;
    }

    if (keyA > keyB) {
      return 1;
    }

    // default return value (no sorting)
    return 0;
  });
}

/**
 * Function for checking args
 * @param {string} argumentName
 */
export function checkArg(argumentName = false) {
  const error = new Error(
    `Missing argument${argumentName ? ` (${argumentName})` : ''}.`,
  );
  Error.captureStackTrace(error, checkArg);
  throw error;
}

export const formatNumber = (
  num,
  options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
) => {
  return Number(num).toLocaleString('en', options);
};

export const valueOrSkeleton = ({
  value,
  loading,
  SkeletonProps,
  valueFormatter,
} = {}) => {
  if (loading || isNil(value)) {
    return (
      <Skeleton
        variant="text"
        style={{ display: 'inline-flex' }}
        width="100%"
        {...SkeletonProps}
      />
    );
  }
  return !!valueFormatter ? valueFormatter(value) : value;
};

/**
 * @example
 *   import keysToCase from './snakeToCamelCase';
 *   keysToCase({bad_key: 1});   => {badKey: 1}
 *   keysToCase([{bad_key: 1}]); => [{badKey: 1}]
 */

export const keysToCase = (objectArg, caseFuction) => {
  let object = cloneDeep(objectArg);

  if (isArray(object)) {
    return map(object, (item) => keysToCase(item, caseFuction));
  }

  if (!isArray(object) && !isPlainObject(object)) {
    return object;
  }

  object = mapKeys(object, (value, key) => {
    return caseFuction(key);
  });

  // Recursively apply throughout object
  return mapValues(object, (value) => {
    if (isPlainObject(value)) {
      return keysToCase(value, caseFuction);
    }
    if (isArray(value)) {
      return map(value, (item) => keysToCase(item, caseFuction));
    }
    return value;
  });
};

export const snakeKeys = (object) => {
  return keysToCase(object, snakeCase);
};

export const camelKeys = (object) => {
  return keysToCase(object, camelCase);
};

export function flattenObject(o, prefix = '', result = {}, keepNull = true) {
  if (isString(o) || isNumber(o) || isBoolean(o) || (keepNull && isNull(o))) {
    // eslint-disable-next-line no-param-reassign
    result[prefix] = o;
    return result;
  }

  if (isArray(o) || isPlainObject(o)) {
    // eslint-disable-next-line guard-for-in
    for (const i in o) {
      let pref = prefix;
      if (isArray(o)) {
        pref += `[${i}]`;
      } else if (isEmpty(prefix)) {
        pref = i;
      } else {
        pref = `${prefix}.${i}`;
      }
      flattenObject(o[i], pref, result, keepNull);
    }
    return result;
  }
  return result;
}

export const formatCategoryView = (categories = [], length = 12) => {
  const countToShow = categories.length - 1;
  const firstElement = categories[0] || '';
  return (
    <Tooltip title={categories.join(', ')} placement="top">
      <span>
        {truncate(firstElement, { length })}
        {countToShow > 0 && <strong> +{countToShow}</strong>}
      </span>
    </Tooltip>
  );
};

export const parseStringVar = (value) => {
  if (typeof value !== 'string') return value;
  if (value === 'true') return true;
  if (value === 'false') return false;
  if (value.trim() === '') return value;
  if (!Number.isNaN(Number(value))) return Number(value);
  return value;
};

export const getEnvVars = () => {
  const result = {};

  Object.keys(process.env).forEach((key) => {
    result[key] = parseStringVar(process.env[key]);
  });

  return result;
};

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import styled, { css } from 'styled-components';
import { BUY, SELL } from 'consts';

export default {
  ToggleButtonGroup: styled(ToggleButtonGroup)`
    background-color: #fff;
    padding: 3px;
    border-radius: 100rem;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  `,

  ToggleButton: styled(ToggleButton)`
    ${({ theme, value }) => css`
      font: normal 700 0.875rem ${theme.typography.fontFamilyAccent};
      min-width: 70px;
      height: 30px;
      border-radius: 15px !important;
      margin-left: 0 !important;
      border: 0;
      color: #616161;

      @media (min-width: 340px) {
        min-width: 80px;
      }

      &.Mui-selected {
        background-color: ${theme.palette.background.dark.main};
        color: ${theme.palette.background.dark.contrastText};

        &:hover {
          background-color: ${theme.palette.background.dark.main};
        }

        ${value === BUY &&
        css`
          color: ${theme.palette.secondary.light};
        `}

        ${value === SELL &&
        css`
          color: ${theme.palette.info.light};
        `}
      }
    `}
  `,
};

import styled, { css } from 'styled-components';
import { AppBar, Container, Link, Typography } from '@material-ui/core';

import Doppler from 'components/Doppler';
import { drawerOpenedWidth, drawerClosedWidth } from '../MainDrawer/styled';

export default {
  FooterWrapper: styled(AppBar)`
    height: 44px;
    z-index: unset;
    /* transition: padding-left 0.1s; */

    ${({ theme, $mainDrawerOpen }) => css`
      ${theme.breakpoints.up('sm')} {
        height: 95px;
        border-top: 0.5px solid #b4b4b4;
      }
      ${theme.breakpoints.up('md')} {
        height: 76px;
        padding-left: ${$mainDrawerOpen
          ? drawerOpenedWidth
          : drawerClosedWidth}px;
      }
    `}
  `,

  Container: styled(Container)`
    height: 100%;
    border-top: 1px solid #d8d8d8;
    margin: 0 1rem;
    width: calc(100% - 2rem);
    padding-top: 10px;
    padding-bottom: 10px;

    ${({ theme }) => css`
      ${theme.breakpoints.up('sm')} {
        border-top: 0;
        margin: auto;
        width: 100%;
      }
      ${theme.breakpoints.up('md')} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 25px;
        padding-bottom: 25px;
        margin: 0 auto;
      }
    `}
  `,

  NavBox: styled(Doppler)``,

  NavLink: styled(Link)`
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: normal;

    &:hover {
      text-decoration: underline;
    }
  `,

  Divider: styled.span`
    &:after {
      content: '/';
      padding: 0 7px;
    }
  `,

  CopyRight: styled(Typography)`
    font-size: 0.75rem;
    margin-bottom: 10px;
    text-align: center;
    ${({ theme }) => css`
      ${theme.breakpoints.up('md')} {
        width: 50%;
        margin-bottom: 0;
        text-align: left;
      }
    `}
  `,
};

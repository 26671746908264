import useMediaQuery from '@material-ui/core/useMediaQuery';

export const useBreakpoints = () => {
  const isPtUp = useMediaQuery((theme) => theme.breakpoints.up('pt'));
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLgDown = useMediaQuery((theme) => theme.breakpoints.down('xl'));

  return {
    isPtUp,
    isSmUp,
    isMdUp,
    isLgUp,
    isSmDown,
    isMdDown,
    isLgDown,
  };
};

/* eslint-disable react-hooks/rules-of-hooks */
import { useQueryString } from 'hooks/hooks';
import { useSelector } from 'react-redux';
import {
  selectHasBuyCampaign,
  selectMe,
  selectIsVerifyConfirm,
} from 'store/slices/accountSlice';

export const checkLoggedInAndVerified = () => {
  const me = useSelector(selectMe);

  if (!!me && (me.emailVerified || me.phoneVerified)) {
    return '/';
  }
};

export const checkLoggedIn = () => {
  const me = useSelector(selectMe);
  const isVerifyConfirmed = useSelector(selectIsVerifyConfirm);
  if (!me) {
    return '/auth/login';
  }
  if (!!me && !me.emailVerified && !me.phoneVerified && !isVerifyConfirmed) {
    return '/auth/verification';
  }
};

export const checkHasNoBuyCampaigns = () => {
  let hasBuyCampaign = useSelector(selectHasBuyCampaign);
  const {
    query: { proceedToBilling },
  } = useQueryString();

  if (proceedToBilling) {
    hasBuyCampaign = false;
  }

  const redirect = checkLoggedIn();

  if (redirect) return redirect;

  if (hasBuyCampaign) {
    return '/';
  }
};

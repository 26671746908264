/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { set } from 'lodash';

const initialState = {};

/**
 * Slice
 */
export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    resetPermissionsState: (state) => {
      state = initialState;
      return state;
    },

    setPermissionsData: (state, action) => {
      state = {
        ...action.payload,
      };

      return state;
    },
  },
});

/**
 * Thunks
 */

export const { resetPermissionsState, setPermissionsData } =
  permissionsSlice.actions;

export const fetchPermissions = () => async (dispatch) => {
  const hash = {};
  try {
    const { data = [] } = await axios.get('/get_permissions/');
    data.sort().forEach((v) => {
      set(hash, v, 1);
    });
  } catch (error) {
    //
  }

  return dispatch(setPermissionsData(hash));
};

/**
 * Selectors
 */
export const selectPermissions = (state) => state.permissions;

export default permissionsSlice.reducer;

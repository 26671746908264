import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Collapse, IconButton } from '@material-ui/core';
import useControlled from '@material-ui/core/utils/useControlled';

import Doppler from 'components/Doppler';
import WrapIf from 'components/WrapIf';
import BlIcon from 'components/icons/BlIcon';

import s from './styled';

const Alert = forwardRef((props, ref) => {
  const {
    open: openProp,
    defaultOpen = true,
    onClose: onCloseProp,
    children,
    TransitionProps = {},
    hideClose,
    bordered,
    ...rest
  } = props;

  const [open, setOpen] = useControlled({
    controlled: openProp,
    default: defaultOpen,
    state: 'open',
    name: 'Alert',
  });

  const onClose = useCallback(
    (_) => {
      setOpen(false);
      onCloseProp?.();
    },
    [setOpen, onCloseProp],
  );

  return (
    <WrapIf
      if={!!TransitionProps}
      Wrapper={Doppler}
      component={Collapse}
      unmountOnExit
      // onExited={onCloseProp}
      {...TransitionProps}
      in={open}
    >
      <s.Alert
        ref={ref}
        $bordered={bordered}
        action={
          !hideClose && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={onClose}
            >
              <BlIcon icon="close" fontSize="inherit" />
            </IconButton>
          )
        }
        {...rest}
      >
        {children}
      </s.Alert>
    </WrapIf>
  );
});

Alert.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  TransitionProps: PropTypes.any,
  defaultOpen: PropTypes.object,
  hideClose: PropTypes.bool,
  bordered: PropTypes.bool,
};

export default Alert;

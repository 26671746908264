import { SpeedDial } from '@material-ui/lab';
import styled from 'styled-components';

export default {
  SpeedDial: styled(SpeedDial)`
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    z-index: 1110;
  `,
};

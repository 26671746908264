import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { fetchAccount } from 'store/slices/accountSlice';
import { fetchAppConfig } from 'store/slices/appConfigSlice';
import { useCustomTheme } from 'hooks/useCustomTheme';
import { THEME_BL } from 'consts';

import s from './styled';

const CriticalRequests = memo(function CriticalRequests(props) {
  const { children, defaultTheme = THEME_BL } = props;

  const [allResolved, setAllResolved] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const { loadTheme } = useCustomTheme();

  useEffect(() => {
    (async () => {
      try {
        const [themeLoaded, meLoaded, appConfigLoaded] = await Promise.all([
          loadTheme(`/api/${defaultTheme}.json`),
          dispatch(fetchAccount()),
          dispatch(fetchAppConfig()),
        ]);

        setAllResolved(!!(themeLoaded && meLoaded && appConfigLoaded));
      } catch (e) {
        setError(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTheme]);

  if (error) {
    throw error;
  }

  if (!allResolved) {
    return (
      <s.CriticalRequestsView>
        <CircularProgress size={60} />
      </s.CriticalRequestsView>
    );
  }

  return children;
});

CriticalRequests.propTypes = {
  children: PropTypes.node,
  defaultTheme: PropTypes.string,
};

export default CriticalRequests;

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { SpeedDialAction } from '@material-ui/lab';
import {
  Filter1,
  Filter2,
  Filter,
  Palette,
  HighlightOff,
} from '@material-ui/icons';

import { THEME_BL, THEME_DEFAULT, THEME_IM } from 'consts';
import { useCustomTheme } from 'hooks/useCustomTheme';

import QueryString from 'qs';
import s from './styled';

const actions = [
  { id: 'hide', icon: <HighlightOff />, name: 'Hide' },
  { id: THEME_DEFAULT, icon: <Filter />, name: 'Default' },
  { id: THEME_BL, icon: <Filter1 />, name: 'BuyerLink' },
  { id: THEME_IM, icon: <Filter2 />, name: 'iMotors' },
];

const { NODE_ENV } = process.env;

const ThemeSelector = memo(function ThemeSelector(props) {
  const { defaultTheme } = props;

  const showComponent = useMemo(() => {
    const developerView = !!localStorage.getItem('developerView');

    if (developerView) return true;

    const parsed = QueryString.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const hasQueryParam = parsed.hasOwnProperty('developerView');

    if (hasQueryParam) {
      localStorage.setItem('developerView', 1);
    }

    return hasQueryParam || NODE_ENV === 'development';
  }, []);

  const [chosenTheme, setChosenTheme] = useState(defaultTheme || THEME_DEFAULT);

  const [hidden, setHidden] = useState(false);
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const { loadTheme, theme } = useCustomTheme();

  useEffect(() => {
    setChosenTheme(theme.id);
  }, [theme.id]);

  const actionsOnClick = useMemo(() => {
    return {
      hide: () => {
        setOpen(false);
        setHidden(true);
        localStorage.removeItem('developerView');
      },
      default: () => {
        setChosenTheme(THEME_DEFAULT);
        loadTheme(null);
      },
      bl: () => {
        setChosenTheme(THEME_BL);
        loadTheme('/api/bl.json');
      },
      im: () => {
        setChosenTheme(THEME_IM);
        loadTheme('/api/im.json');
      },
    };
  }, [loadTheme]);

  const FabProps = useMemo(() => ({ disabled: true }), []);

  if (!showComponent) return null;

  return (
    <s.SpeedDial
      ariaLabel="SpeedDial example"
      icon={<Palette />}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      direction="up"
      hidden={hidden}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={actionsOnClick[action.id]}
          FabProps={chosenTheme === action.id ? FabProps : undefined}
        />
      ))}
    </s.SpeedDial>
  );
});

ThemeSelector.propTypes = {
  defaultTheme: PropTypes.string,
};

export default ThemeSelector;

/* eslint-disable no-param-reassign */
import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { camelCase } from 'lodash';
import Cookies from 'js-cookie';

// eslint-disable-next-line no-unused-vars
const { REACT_APP_API_URI } = process.env;

/**
 * Sets default setting for the axios.
 */
const configureAxios = () => {
  axios.defaults.baseURL = REACT_APP_API_URI;

  axios.defaults.headers.common = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  // axios.defaults.withXSRFToken = true;
  axios.defaults.xsrfHeaderName = 'x-csrftoken';
  axios.defaults.xsrfCookieName = 'csrftoken';

  /**
   * Interceptors
   */
  // CSRFToken save in cookie
  axios.interceptors.response.use((config) => {
    const token = config.headers[axios.defaults.xsrfHeaderName];

    if (!!token) {
      Cookies.set(`${axios.defaults.xsrfCookieName}`, token, {
        expires: 365,
        sameSite: 'None',
        secure: true,
      });
    }

    return config;
  });

  // Needed for CORS requests, only for our base url
  axios.interceptors.request.use((config) => {
    if (!config.url.includes('http')) {
      config.withCredentials = true;
      config.crossdomain = true;
      config.withXSRFToken = true;
    }

    return config;
  });

  // Enable transformation of snake_case to camelCase for response body
  const caseMiddlewareOptions = {
    preservedKeys: (input) => {
      return !input.includes('_') || input.includes('-');
    },
    caseFunctions: {
      camel: camelCase,
    },
    caseMiddleware: {
      requestTransformer: (config) => config,
    },
  };

  applyCaseMiddleware(axios, caseMiddlewareOptions);
};

const axiosBaseQuery = async (params) => {
  try {
    const result = await axios(params);
    return { data: result.data };
  } catch (axiosError) {
    return {
      error: {
        status: axiosError.response?.status,
        data: axiosError.response?.data,
        message: axiosError.message,
      },
    };
  }
};

export { configureAxios, axiosBaseQuery };

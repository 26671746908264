import styled from 'styled-components';

export default {
  Root: styled.span`
    position: relative;
    width: 1em;
    height: 1em;
    font-size: 1.25rem;
    padding: 2px;
  `,

  RadioIcon: styled.span`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    /* font-size: 1.125rem; */
    border: 1px solid ${({ theme }) => theme.palette.action.disabled};
    border-radius: 100rem;
    transition: border-color 0.3s;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: currentColor;
      border-radius: 100rem;
      transform: scale(0);
      transition: transform 0.3s;
    }

    ${({ $checked, theme }) => {
      if (!$checked) return '';

      return `
        border-color: ${theme.palette.action.active};

        &::after {
          transform: scale(0.75);
        }
      `;
    }}
  `,
};

/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { parse, isValid } from 'date-fns';

// Set locale
import locale from './locale';

const urlRegExp = new RegExp(
  '^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%\\[\\]_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%\\[\\]_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
  'i',
);

const postcodeRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const initYup = ({ phoneMask = '(000) 000-0000' } = {}) => {
  // const phoneRegExp = phoneMask
  //   .replace(/0/g, '\\d')
  //   .replace(/\(/g, '\\(')
  //   .replace(/\)/g, '\\)');

  const phoneDigitsAmount = phoneMask.match(/0/g)?.length;

  Yup.addMethod(
    Yup.string,
    'phone',

    function phone(message = locale.string.phone) {
      return this.test({
        name: 'phone',
        message,
        params: { digitsAmount: phoneDigitsAmount },
        test(value) {
          if (!value) return true;

          const digitsOnly = value.match(/\d/g);

          if (!digitsOnly?.length) return false;

          return digitsOnly.length === phoneDigitsAmount;
        },
      });
    },
  );

  Yup.addMethod(
    Yup.string,
    'postcode',
    function postcode(message = locale.string.postcode) {
      return this.matches(postcodeRegExp, {
        name: 'postcode',
        message,
      });
    },
  );

  Yup.addMethod(Yup.string, 'url', function url(message = locale.string.url) {
    return this.test({
      name: 'url',
      message,
      test(value) {
        if (!value) return true;

        return urlRegExp.test(value);
      },
    });
  });

  Yup.addMethod(
    Yup.string,
    'onlyLetters',
    function onlyLetters(message = locale.string.onlyLetters) {
      return this.matches(/^[aA-zZ\s]+$/, { name: 'onlyLetters', message });
    },
  );

  Yup.addMethod(
    Yup.string,
    'notOnlyNumbers',
    function notOnlyNumbers(message = locale.string.notOnlyNumbers) {
      return this.matches(/(?!^\d+$)^.+$/, { name: 'notOnlyNumbers', message });
    },
  );

  Yup.addMethod(
    Yup.string,
    'includesUppercase',
    function includesUppercase(message = locale.string.includesUppercase) {
      return this.matches(/(?=.*[A-Z])/, {
        name: 'includesUppercase',
        message,
      });
    },
  );

  Yup.addMethod(
    Yup.string,
    'includesLowercase',
    function includesLowercase(message = locale.string.includesLowercase) {
      return this.matches(/(?=.*[a-z])/, {
        name: 'includesLowercase',
        message,
      });
    },
  );

  Yup.addMethod(
    Yup.string,
    'includesDigit',
    function includesDigit(message = locale.string.includesDigit) {
      return this.matches(/(?=.*\d)/, { name: 'includesDigit', message });
    },
  );

  Yup.addMethod(
    Yup.string,
    'includesSpecialChar',
    function includesSpecialChar(message = locale.string.includesSpecialChar) {
      return this.matches(/(.*\W.*)/, { name: 'includesSpecialChar', message });
    },
  );

  Yup.addMethod(
    Yup.object,
    'minKeys',
    function minKeys(keysAmount, message = locale.string.minKeys) {
      return this.test({
        name: 'minKeys',
        message,
        params: { keysAmount },
        test(value) {
          if (!value) return true;

          return Object.keys(value).length >= keysAmount;
        },
      });
    },
  );

  Yup.addMethod(
    Yup.string,
    'time',
    function time(message = locale.string.time) {
      return this.test({
        name: 'time',
        message,
        test(value) {
          if (!value) return true;
          const date = parse(value, 'hh:mm a', new Date());
          return isValid(date);
        },
      });
    },
  );

  Yup.addMethod(
    Yup.array,
    'unique',
    function unique(message = locale.array.unique, mapper = (a) => a) {
      return this.test({
        name: 'unique',
        message,
        test(list) {
          return list.length === new Set(list.map(mapper)).size;
        },
      });
    },
  );

  Yup.addMethod(
    Yup.array,
    'uniqueDay',
    function uniqueFn(message, initialDays) {
      return this.test({
        name: 'uniqueDay',
        message,
        test(list, { parent }) {
          if (!parent.startTime || !parent.endTime) return true;
          const restWeekdays = initialDays?.filter((item) => {
            return (
              item.index !== parent.index && item.startTime && item.endTime
            );
          });
          const isUnique = [];
          list.forEach((day) => {
            const isUniqueDay = restWeekdays.every(
              ({ weekday, startTime, endTime }) => {
                if (!startTime && !endTime) return true;
                return !weekday.includes(day);
              },
            );
            isUnique.push(isUniqueDay);
          }, []);
          return isUnique.every((el) => el);
        },
      });
    },
  );

  Yup.setLocale(locale);
};

initYup();

import React, { useEffect } from 'react';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useSelector } from 'react-redux';
import { ConfirmProvider } from 'react-use-confirm';

import { initYup } from 'helpers/yup';
import { configureAxios } from 'helpers/axios';
import { useCustomTheme } from 'hooks/useCustomTheme';
// import { ConfirmProvider } from 'hooks/useConfirm';
import { GlobalStyles } from 'styles/global';
import CriticalRequests from 'containers/CriticalRequests';
import ThemeProvider from 'components/theme/ThemeProvider';
import { selectPhoneMask } from 'store/slices/appConfigSlice';
import ConfirmDialog from 'components/ConfirmDialog';
import { ErrorBoundary } from '@sentry/react';
// import ErrorBoundary from 'components/ErrorBoundary';

import 'styles/vendor/reset.css';

import Routes from './Routes/Routes';
import AppMeta from './AppMeta';
import AppErrorFallback from './AppErrorFallback';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

configureAxios();

function App() {
  const { theme } = useCustomTheme();

  const phoneMask = useSelector(selectPhoneMask);

  useEffect(() => {
    if (!phoneMask) return;

    initYup({ phoneMask });
  }, [phoneMask]);

  return (
    <>
      <AppMeta />
      <ErrorBoundary fallback={<AppErrorFallback theme={theme} />}>
        <CriticalRequests>
          <StylesProvider jss={jss}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={theme}>
                <GlobalStyles />
                <ConfirmProvider ConfirmComponent={ConfirmDialog}>
                  <Routes />
                </ConfirmProvider>
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </CriticalRequests>
      </ErrorBoundary>
    </>
  );
}

export default App;

import { useCallback, useEffect } from 'react';
import { useLocalstorageState } from 'rooks';
import { EventEmitter } from 'eventemitter3';

const ee = new EventEmitter();
const SET = 'SET';

/**
 * Wrapper for 'useLocalstorageState' to rerender other components
 * on value change, assosiated with the same key.
 *
 * @param {string} key
 * @param {any} initialValue
 * @returns {array}
 */
const useLS = (key, initialValue) => {
  const [value, setValue] = useLocalstorageState(key, initialValue);

  const wrappedSetValue = useCallback(
    (v) => {
      setValue(v);

      ee.emit(`${key}::${SET}`, v);
    },
    [key, setValue],
  );

  useEffect(() => {
    const listener = (eventValue) => {
      const lsValue = JSON.parse(localStorage.getItem(key));

      if (lsValue === eventValue) {
        return;
      }

      setValue(eventValue);
    };

    ee.on(`${key}::${SET}`, listener);

    return () => {
      ee.off(`${key}::${SET}`, listener);
    };
  }, [key, setValue]);

  return [value, wrappedSetValue];
};

export { useLS };

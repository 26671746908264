import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Warning } from '@material-ui/icons';
import { Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import BlIcon from 'components/icons/BlIcon';
// import { StepperContext } from '../Stepper/Stepper';

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: 'block',
    color: theme.palette.text.secondary,
    fontSize: '2.5rem',

    '&$completed': {
      color: 'inherit',

      '& $circle': {
        backgroundColor: 'currentColor',
      },
    },
    '&$active': {
      color: 'inherit',
    },
    '&$error': {
      color: theme.palette.error.main,
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
    },
  },
  /* Styles applied to the SVG text element. */
  text: {
    color: 'currentColor',
    fontSize: theme.typography.htmlFontSize,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
  },

  check: {
    color: '#fff',
  },
  /* Pseudo-class applied to the root element if `active={true}`. */
  active: {},
  /* Pseudo-class applied to the root element if `completed={true}`. */
  completed: {},
  /* Pseudo-class applied to the root element if `error={true}`. */
  error: {},

  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderRadius: '100rem',
    border: '3px solid currentColor',
  },

  // colorPrimary: {
  //   '&$completed': {
  //     color: 'inherit',
  //   },
  //   '&$active': {
  //     color: 'inherit',
  //   },
  // },

  // colorSecondary: {
  //   '&$completed': {
  //     color: 'inherit',
  //   },
  //   '&$active': {
  //     color: 'inherit',
  //   },
  // },
});

const StepIcon = React.forwardRef(function StepIcon(props, ref) {
  const {
    completed = false,
    icon,
    active = false,
    error = false,
    classes,
  } = props;

  // const { color = 'primary' } = useContext(StepperContext);

  if (typeof icon === 'number' || typeof icon === 'string') {
    const className = clsx(classes.root, {
      // [classes.colorPrimary]: color === 'primary',
      // [classes.colorSecondary]: color === 'secondary',
      [classes.active]: active,
      [classes.error]: error,
      [classes.completed]: completed,
    });

    if (error) {
      return <Warning className={className} ref={ref} />;
    }

    // if (active && isMdUp) {
    //   return <BlIcon icon="progress" className={className} ref={ref} />;
    // }

    return (
      <Icon className={className} ref={ref}>
        <div className={classes.circle}>
          {completed && <BlIcon icon="check" className={classes.check} />}
          {!completed && <p className={classes.text}>{icon}</p>}
        </div>
      </Icon>
    );
  }

  return icon;
});

StepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * Mark the step as failed.
   */
  error: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default withStyles(styles, { name: 'MuiStepIcon' })(StepIcon);

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import { useBreakpoints } from 'helpers/styles';

import clsx from 'clsx';
import s from './styled';
import { footerLinks } from './helpers';

const Footer = memo(function Footer({ mainDrawerOpen, className, ...props }) {
  const { isSmUp } = useBreakpoints();
  const year = new Date().getFullYear();

  return (
    <s.FooterWrapper
      position="static"
      component="footer"
      color="default"
      $isSmUp={isSmUp}
      $mainDrawerOpen={mainDrawerOpen}
      className={clsx('Footer-root', className)}
      {...props}
    >
      <s.Container>
        <s.CopyRight>©{year} BuyerLink.com. All Rights Reserved.</s.CopyRight>

        {isSmUp && (
          <s.NavBox>
            {footerLinks.map((link, i) => {
              let jsx = (
                <s.NavLink color="inherit" component={Link} to={link.url}>
                  {link.label}
                </s.NavLink>
              );
              if (link.isExternalLink) {
                jsx = (
                  <s.NavLink
                    color="inherit"
                    component="a"
                    target="_blank"
                    href={link.url}
                  >
                    {link.label}
                  </s.NavLink>
                );
              }
              return (
                <React.Fragment key={link.label}>
                  {i !== 0 && <s.Divider className="Divider-root" />}
                  {jsx}
                </React.Fragment>
              );
            })}
          </s.NavBox>
        )}
      </s.Container>
    </s.FooterWrapper>
  );
});

Footer.propTypes = {
  mainDrawerOpen: PropTypes.bool,
  className: PropTypes.string,
};

export default Footer;

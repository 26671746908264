import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import s from './styled';

const CheckIcon = memo(function CheckIcon(props) {
  const { checked, className } = props;

  return (
    <s.CheckIcon
      $checked={checked}
      className={clsx(className, 'CheckIcon-root')}
      {...props}
    />
  );
});

CheckIcon.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
};

export default CheckIcon;

import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle(
  ({ theme }) => css`
    ${() => {
      const hostedFonts = theme.custom?.hostedFonts;

      if (!hostedFonts?.length) return '';

      let imports = '';

      hostedFonts.forEach((font) => {
        const { url, fontFamily, fontWeight } = font;

        imports += `
          @font-face {
            font-family: '${fontFamily}';
            src:  url('${url}.woff2') format('woff2'),
                  url('${url}.woff') format('woff');
            font-weight: ${fontWeight};
            font-style: normal;
            font-display: swap;
          }
        `;
      });

      return imports;
    }}

    html {
      font-size: ${theme.typography.htmlFontSize}px;
      font-weight: 400;
      box-sizing: border-box;
    }

    body {
      font-size: ${theme.typography.htmlFontSize}px;
      font-family: ${theme.typography.fontFamily};
      background-color: ${theme.palette.background.default};
      color: ${theme.palette.text.primary};
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    select {
      font: inherit;

      option {
        transition: background 0s;
      }

      &[multiple] option:checked,
      &[multiple]:focus option:checked {
        background: ${theme.palette.secondary.light}
          linear-gradient(
            0deg,
            ${theme.palette.secondary.light} 0%,
            ${theme.palette.secondary.light} 100%
          );
      }
    }

    a {
      text-decoration: none;
    }

    [hidden],
    .d-none-i {
      display: none !important;
    }

    strong {
      font-weight: 600;
    }

    em {
      font-style: italic;
    }

    img,
    svg {
      user-select: none;
      height: auto;
      max-width: 100%;
    }

    svg {
      display: block;
    }

    video {
      display: block;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .text-overflow-ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .break-word {
      word-break: break-word;
    }

    .pointer-none {
      pointer-events: none;
    }

    .color-dark {
      color: ${theme.palette.background.dark.main};
    }
  `,
);

import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container, Typography } from '@material-ui/core';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';

import { selectIsLoggedIn } from 'store/slices/accountSlice';
import View from 'layouts/View';

import s from './styled';

const NotFound = memo(function NotFound({
  title = '404',
  text = 'Not found',
  content = text,
  Layout: LayoutProp,
}) {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const Layout = useMemo(() => {
    if (LayoutProp) return LayoutProp;

    return isLoggedIn ? s.Layout : View;
  }, [LayoutProp, isLoggedIn]);

  return (
    <Layout align="center">
      <Helmet>
        <title>
          {title} {text}
        </title>
      </Helmet>

      <Container>
        <Typography variant="h1" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" align="center">
          {content}
        </Typography>
      </Container>
    </Layout>
  );
});

NotFound.propTypes = {
  title: PropTypes.node,
  text: PropTypes.string,
  content: PropTypes.node,
  Layout: PropTypes.func,
};

export default NotFound;

import { Alert } from '@material-ui/lab';
import styled, { css } from 'styled-components';

export default {
  Alert: styled(Alert)`
    ${({ $bordered, theme }) =>
      $bordered &&
      css`
        border-radius: 4px;
        border-top: 5px solid ${theme.palette.background.dark.main};

        ${theme.breakpoints.up('sm')} {
          border-top: 0;
          border-left: 10px solid ${theme.palette.background.dark.main};
        }
      `}
  `,
};

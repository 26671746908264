import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link as RRLink } from 'react-router-dom';
import { useTimeoutWhen, useForkRef, useInViewRef } from 'rooks';

import { preloadRouteComponent } from 'views/_app/Routes/helpers';

const Link = forwardRef(function Link(props, outerRef) {
  const {
    to,
    scroll = true,
    onClick: onClickProp,
    onMouseEnter: onMouseEnterProp,
    ...rest
  } = props;

  /**
   * Preload components when link is in viewport
   */
  const { inViewRef, isVisible } = useInViewRef();

  const ref = useForkRef(inViewRef, outerRef);

  useTimeoutWhen(
    () => {
      preloadRouteComponent(to);
    },
    2000,
    isVisible,
  );

  const onMouseEnter = useCallback(
    (...all) => {
      preloadRouteComponent(to);
      onMouseEnterProp?.(...all);
    },
    [onMouseEnterProp, to],
  );

  /**
   * Scroll to top, on click
   */

  const onClick = useCallback(
    (...all) => {
      if (scroll) {
        window.scrollTo(0, 0);
      }

      onClickProp?.(...all);
    },
    [onClickProp, scroll],
  );

  return (
    <RRLink
      ref={ref}
      to={to}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      {...rest}
    />
  );
});

Link.propTypes = {
  to: PropTypes.any,
  scroll: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
};

export default Link;

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { setupListeners } from '@reduxjs/toolkit/query';
import accountReducer from './slices/accountSlice';
import appConfigReducer from './slices/appConfigSlice';
import permissionsReducer from './slices/permissionsSlice';

import { rootApi } from './slices/rootApi';

const combinedReducer = combineReducers({
  // Add the generated reducer as a specific top-level slice
  [rootApi.reducerPath]: rootApi.reducer,

  account: accountReducer,
  appConfig: appConfigReducer,
  permissions: permissionsReducer,
});

let initialState;
const rootReducer = (state, action) => {
  if (action.type === 'resetStore') {
    // To prevent RTK query warning, it is supposed that
    // resetStore will be called after this middleware is registered.
    // But in initialState it would be false, if we won't set it manually.
    if (!initialState.api.config.middlewareRegistered) {
      initialState.api.config.middlewareRegistered =
        state.api.config.middlewareRegistered;
    }

    // eslint-disable-next-line no-param-reassign
    state = initialState;
  }
  return combinedReducer(state, action);
};

export const createStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(rootApi.middleware),
  });

  // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
  // see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
  setupListeners(store.dispatch);

  return store;
};

const store = createStore();
initialState = store.getState();
initialState = cloneDeep(initialState);

store.resetStore = () => {
  store.dispatch({ type: 'resetStore' });
};

if (process.env.NODE_ENV === 'development') {
  window.store = store;
}

export default store;

import styled, { css } from 'styled-components';
import View from 'layouts/View';
import { drawerClosedWidth, drawerOpenedWidth } from './MainDrawer/styled';

export default {
  View: styled(View)`
    justify-content: flex-start;
    padding-bottom: 75px;

    ${({ theme }) => css`
      ${theme.breakpoints.up('sm')} {
        padding-bottom: 0;
      }

      ${
        '' /* &.Layout-drawerOpened .useErrorToast-alertBox {
        left: calc(${drawerOpenedWidth}px + 1.125rem);
        width: calc(100% - ${drawerOpenedWidth}px);
      } */
      }
    `}
  `,

  DrawerBox: styled.div`
    display: flex;
    flex-grow: 1;
    width: 100%;
  `,

  MainBox: styled.div`
    display: flex;
    flex-direction: column;
    min-height: inherit;
    width: 100%;

    ${({ theme, $mainDrawerOpen }) => css`
      ${theme.breakpoints.up('md')} {
        ${$mainDrawerOpen &&
        css`
          width: calc(100% - ${drawerOpenedWidth}px);
        `}

        ${!$mainDrawerOpen &&
        css`
          width: calc(100% - ${drawerClosedWidth}px);
        `}
      }
    `}
  `,

  Main: styled.main`
    padding-top: 18px;
    padding-bottom: 18px;
    height: 100%;
  `,
};

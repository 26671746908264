import { useLocation } from 'react-router';

import { BUY, CLICKS, LEADS, SELL } from 'consts';

export const useRouterHelper = () => {
  const { pathname } = useLocation();

  const result = {
    isBuy: pathname.includes(`/${BUY}/`),
    isSell: pathname.includes(`/${SELL}/`),
    isLeads: pathname.includes(`/${LEADS}/`),
    isClicks: pathname.includes(`/${CLICKS}/`),
    isAccount: pathname.includes('/account/'),
    isFinance: pathname.includes('/finances/'),
  };

  if (result.isLeads) {
    result.productType = LEADS;
  }
  if (result.isClicks) {
    result.productType = CLICKS;
  }

  return result;
};

import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

import { useMarketTypeLS } from 'hooks/useMarketTypeLS';
import { BUY, CLICKS, LEADS, SELL } from 'consts';
import Link from 'components/Link';
import { selectBuyer } from 'store/slices/accountSlice';

import s from './styled';

const MarketTypeToggleGroup = memo(function MarketTypeToggleGroup(props) {
  const { marketType, onMarketTypeChange } = useMarketTypeLS();
  const buyer = useSelector(selectBuyer, shallowEqual);
  const { isClickBuyer } = buyer || {};

  return (
    <s.ToggleButtonGroup
      exclusive
      onChange={onMarketTypeChange}
      value={marketType}
      {...props}
    >
      <s.ToggleButton
        value={BUY}
        aria-label="buy"
        component={Link}
        to={`/${BUY}/${isClickBuyer ? CLICKS : LEADS}/overview`}
      >
        Buy
      </s.ToggleButton>
      <s.ToggleButton
        value={SELL}
        aria-label="sell"
        component={Link}
        to={`/${SELL}/${LEADS}/overview`}
      >
        Sell
      </s.ToggleButton>
    </s.ToggleButtonGroup>
  );
});

MarketTypeToggleGroup.propTypes = {};

export default MarketTypeToggleGroup;

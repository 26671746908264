import styled, { css } from 'styled-components';
import { AppBar } from '@material-ui/core';

import Doppler from 'components/Doppler';
import BlIcon from 'components/icons/BlIcon';
import Link from 'components/Link';
import MarketTypeToggleGroup from 'containers/MarketTypeToggleGroup';
import { drawerClosedWidth, drawerOpenedWidth } from '../MainDrawer/styled';

export default {
  HeaderAppBarSpacing: styled(Doppler)`
    display: none;
    height: 70px;
    flex-shrink: 0;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      display: block;
    }
  `,

  HeaderAppBar: styled(AppBar)`
    display: flex;
    align-items: center;
    height: 70px;
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1100;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    transition: left 0.15s linear;

    ${({ theme, $open }) => css`
      ${theme.breakpoints.up('sm')} {
        top: 72px;
        bottom: auto;
        z-index: ${theme.zIndex.appBar};
        border-top-width: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      }

      ${theme.breakpoints.up('md')} {
        top: 0;
        left: ${$open ? `${drawerOpenedWidth}px` : `${drawerClosedWidth}px`};
        max-width: calc(
          100% - ${$open ? drawerOpenedWidth : drawerClosedWidth}px
        );
      }
    `}

    & .Header-container {
      height: 100%;

      ${({ theme }) => theme.breakpoints.up('sm')} {
        display: flex;
        align-items: center;
      }
    }
  `,

  NavBox: styled(Doppler)`
    display: flex;
    align-items: center;
    height: 100%;
  `,

  NavLink: styled(Link)`
    text-decoration: none;
    flex: 1;
    text-align: center;

    &:last-child {
      margin-right: 0;
    }

    ${({ theme, $active }) => css`
      color: ${theme.palette.text.hint};
      font: 700 0.875rem ${theme.typography.fontFamilyAccent};

      ${$active &&
      css`
        color: ${theme.palette.background.dark.main};
      `}

      ${theme.breakpoints.up('sm')} {
        flex: initial;
        text-align: left;
        margin-right: 40px;
        font-size: 1.125rem;
      }
    `}
  `,

  NavLinkIcon: styled(BlIcon)`
    display: block;
    margin: auto;
    font-size: 1.5rem;
  `,

  MarketTypeToggleGroup: styled(MarketTypeToggleGroup)`
    margin-left: auto;
  `,
};

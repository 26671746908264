import { useCallback, useEffect, useMemo, useState } from 'react';
import QueryString from 'qs';
import { useHistory, useLocation } from 'react-router';

const { NODE_ENV } = process.env;

/**
 * Router
 */
export const useQueryString = () => {
  const location = useLocation();
  const history = useHistory();

  const query = useMemo(() => {
    const parsed = QueryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    return parsed;
  }, [location.search]);

  const pushQuery = useCallback(
    (queryArg, replace) => {
      const func = replace ? history.replace : history.push;

      func({
        pathname: location.pathname,
        search: QueryString.stringify(queryArg, {
          addQueryPrefix: true,
        }),
      });
    },
    [history, location.pathname],
  );

  return { query, pushQuery };
};

/**
 * Use is mounted
 *
 * @returns {Boolean} isMounted
 */
export const useIsMounted = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted;
};

/**
 * Is developer
 */
export const useIsDev = () => {
  return {
    isDev:
      NODE_ENV === 'development' || !!localStorage.getItem('developerView'),
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import ThemeSelector from 'components/theme/ThemeSelector';

import s from './styled';

const View = (props) => {
  const { children, align, ...rest } = props;

  return (
    <s.View $align={align} {...rest}>
      {children}

      <ThemeSelector />
    </s.View>
  );
};

View.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
};

export default View;

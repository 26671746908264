import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

import Link from 'components/Link';
import BlIcon from 'components/icons/BlIcon';

import { useBreakpoints } from 'helpers/styles';
import s from './styled';

const MobileHeader = (props) => {
  const { isSmUp } = useBreakpoints();

  const {
    open: openProp,
    onToggle: onToggleProp,
    position = 'fixed',
    ...rest
  } = props;

  const {
    custom: { elements: { logoBig, logoSmall } = {} },
  } = useTheme();

  const [open, setOpen] = useState(openProp);

  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  const onToggle = useCallback(
    (event) => {
      setOpen((o) => {
        onToggleProp(event, !o);
        return !o;
      });
    },
    [onToggleProp],
  );

  return (
    <>
      <s.MobileHeaderWrapper position={position} {...rest}>
        <s.Toolbar>
          <s.LogoBox component={Link} to="/">
            {logoBig && isSmUp && <img src={logoBig} alt="Logo" />}
            {logoSmall && !isSmUp && <img src={logoSmall} alt="Logo" />}
          </s.LogoBox>

          <s.MenuToggleButton onClick={onToggle} $open={open}>
            <BlIcon icon={open ? 'close-thin' : 'menu'} />
          </s.MenuToggleButton>
        </s.Toolbar>
      </s.MobileHeaderWrapper>

      {position === 'fixed' && <s.Spacer />}
    </>
  );
};

MobileHeader.propTypes = {
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  position: PropTypes.string,
};

export default MobileHeader;
